import React, { useEffect, useState, useRef } from "react";
import { Box, Container, VStack, Button, HStack, Input } from "@chakra-ui/react"
import Message from "./Components/Message";
import { app } from './firebase'
import { getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth'
import { query, orderBy, getFirestore, addDoc, collection, serverTimestamp, onSnapshot } from 'firebase/firestore'


const auth = getAuth(app)
const db = getFirestore(app)

const signInHandler = () => {
  const provider = new GoogleAuthProvider()
  signInWithPopup(auth, provider)
}

const signOutHandler = () => {
  signOut(auth)
}

function App() {
  const [user, setUser] = useState(false)
  const [message, setMessage] = useState("")
  const [messages, setMessages] = useState([])

  const divForScroll = useRef(null)

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      setMessage('')
      await addDoc(collection(db, "Message"), {
        text: message,
        uid: user.uid,
        uri: user.photoURL,
        createdAt: serverTimestamp()
      })
      divForScroll.current.scrollIntoView({ behavior: "smooth" })
    } catch (error) {
      alert(error);
    }
  }


  useEffect(() => {
    const q = query(collection(db, "Message"), orderBy("createdAt", "asc"))
    const unsubscribe = onAuthStateChanged(auth, (data) => {
      setUser(data)
    })
    const unsubscribeForMessage = onSnapshot(q, (snap) => {
      setMessages(snap.docs.map(item => {
        const id = item.id;
        return { id, ...item.data() };
      }));
    })
    return () => {
      unsubscribe();
      unsubscribeForMessage();
    }
  }, [])

  return (
    <Box bg={'red.50'}>
      {
        user ? (
          <Container bg={'white'} h={'100vh'}>
            <VStack h={'full'} padding={'4'} >
              <Button onClick={signOutHandler} w={'full'} colorScheme={'red'}>Logout</Button>
              <VStack h={'full'} w={'full'} overflowY={'auto'} css={{
                "&::-webkit-scrollbar": {
                  display: "none"
                },
              }}>

                {
                  messages.map(item => (
                    <Message key={item.id} user={item.uid === user.uid ? "me" : "other"} text={item.text} uri={item.uri} />
                  ))
                }
                <div ref={divForScroll}></div>
              </VStack>
              <form onSubmit={submitHandler} style={{ width: '100%' }}>
                <HStack>
                  <Input value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Enter message" />
                  <Button type="submit" colorScheme={'purple'}>Send</Button>
                </HStack>
              </form>
            </VStack>
          </Container>
        ) : (
          <VStack justifyContent={'center'} h={'100vh'}>
            <Button colorScheme={'purple'} onClick={signInHandler}>Sign in with Google</Button>
          </VStack>
        )
      }
    </Box>
  );
}

export default App;
