import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDSzqmOZWdzvLycZA-aVO0yVlM4cfYJNvk",
  authDomain: "mychat-app-f9d1d.firebaseapp.com",
  projectId: "mychat-app-f9d1d",
  storageBucket: "mychat-app-f9d1d.appspot.com",
  messagingSenderId: "643720782618",
  appId: "1:643720782618:web:775145c9bb4e3de31782a3"
};

export const app = initializeApp(firebaseConfig);